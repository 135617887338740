// Chakra React Imports
import { AiFillEdit } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import {
	Box,
	Flex,
	Text,
	Grid,
	Button,
	Switch,
	Spinner,
	GridItem,
	useToast,
} from "@chakra-ui/react";

// Component Imports
import CreateRewardStore from "./CreateRewardStore";
import UploadRewardStoreImage from "./UploadRewardStoreImage";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syThemeBgYellowColor,
	syThemeLabelColor,
	syWhite,
} from "../../../constants/color";

// Api Imports
import {
	fetchStoreRewardList,
	fetchStoreRewardClass,
	fetchStoreRewardGroup,
	handleStoreStatus,
} from "../../../services/goalServices";

function RewardStore() {
	const toast = useToast();
	const [classList, setClassList] = useState([]);
	const [groupList, setGroupList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [classLoader, setClassLoader] = useState(false);
	const [groupLoader, setGroupLoader] = useState(false);
	const [selectedReward, setSelectedReward] = useState({});
	const [isEditClicked, setIsEditClicked] = useState(false);
	const [storeRewardList, setStoreRewardList] = useState([]);

	useEffect(() => {
		renderRewardList();
	}, []);

	const renderRewardList = () => {
		getStoreRewardClass();
		getStoreRewardGroup();
		getStoreRewardList();
	};

	// Todo:Fetch Reward Class List
	const getStoreRewardClass = () => {
		setClassLoader(true);
		let obj = {
			key: "string",
		};
		fetchStoreRewardClass(obj)
			.then(res => {
				setClassLoader(false);
				if (res?.data?.length && res?.result) {
					setClassList(res?.data);
				} else {
					setClassList([]);
				}
			})
			.catch(err => {
				setClassLoader(false);
				if (err) {
					setClassList([]);
				}
			});
	};

	// Todo:Fetch Reward Group List
	const getStoreRewardGroup = () => {
		setGroupLoader(true);
		let obj = {
			key: "string",
		};
		fetchStoreRewardGroup(obj)
			.then(res => {
				setGroupLoader(false);
				if (res?.data?.length && res?.result) {
					setGroupList(res?.data);
				} else {
					setGroupList([]);
				}
			})
			.catch(err => {
				setGroupLoader(false);
				if (err) {
					setGroupList([]);
				}
			});
	};

	// Todo:Fetch Reward Store List
	const getStoreRewardList = () => {
		setLoading(true);
		let obj = {
			filter_key: "",
			filter_value: "",
		};
		fetchStoreRewardList(obj)
			.then(res => {
				setLoading(false);
				if (res?.data?.length && res?.result) {
					setStoreRewardList(res?.data);
				} else {
					setStoreRewardList([]);
				}
			})
			.catch(err => {
				setLoading(false);
				if (err) {
					setStoreRewardList([]);
				}
			});
	};

	// Todo:Render Class List
	function renderClassList() {
		if (classList && classList.length > 0) {
			return classList.map((data, index) => (
				<Button
					size="xs"
					colorScheme="pink"
					mr="2"
					textTransform={"capitalize"}
				>
					{data?.class_name}
				</Button>
			));
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text
						mb="5px"
						fontSize={"16px"}
						fontWeight={"bold"}
						color={syThemeBgYellowColor}
					>
						{"No Class List Found!!"}
					</Text>
				</Box>
			);
		}
	}

	// Todo:Render group list
	function renderGroupList() {
		if (groupList && groupList.length > 0) {
			return groupList.map((data, index) => (
				<Button
					size="xs"
					colorScheme="purple"
					mr="2"
					textTransform={"capitalize"}
				>
					{data?.group_name}
				</Button>
			));
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text
						mb="5px"
						fontSize={"16px"}
						fontWeight={"bold"}
						color={syThemeBgYellowColor}
					>
						{"No Class List Found!!"}
					</Text>
				</Box>
			);
		}
	}

	// Todo:Handle Reward Status
	const handleRewardStatus = data => {
		console.log(data);
		let obj = {
			reward_item_uuid: data?.reward_item_uuid,
		};
		handleStoreStatus(obj)
			.then(res => {
				if (res?.result) {
					toast(toastFunctionToaster(res?.message, "success"));

					getStoreRewardList();
				} else {
					toast(toastFunctionToaster(res?.message, "err"));
				}
			})
			.catch(err => {
				if (err) {
					toast(toastFunctionToaster(err?.message, "success"));
				}
			});
	};

	// Todo:Render store reward List
	function renderStoreRewardList() {
		if (storeRewardList && storeRewardList.length > 0) {
			return storeRewardList.map((data, index) => (
				<Flex
					p="10px"
					bg={"#1c181e"}
					color={syWhite}
					borderLeft={"10px"}
					borderStyle={"solid"}
					borderLeftColor={
						data?.reward_item_status ? "green.200" : "red.200"
					}
					mb="4"
					flexWrap={"wrap"}
				>
					<Flex w="100%" alignItems={"center"}>
						<Text fontSize={"20px"} mr="3">
							{data?.reward_item_name}
						</Text>
						<Flex>
							<Button
								size="xs"
								colorScheme="purple"
								mr="2"
								textTransform={"capitalize"}
							>
								{data?.reward_item_group}
							</Button>
							<Button
								size="xs"
								colorScheme="pink"
								mr="2"
								textTransform={"capitalize"}
							>
								{data?.reward_item_class}
							</Button>
						</Flex>
					</Flex>
					<Flex
						justifyContent={"space-between"}
						w="100%"
						alignItems={"center"}
					>
						<Grid
							w="90%"
							templateColumns="repeat(15, 1fr)"
							gap={1}
							mt="3"
						>
							<GridItem
								h="20"
								w="20"
								bg="blue.500"
								borderRadius={"10px"}
							/>
							<GridItem
								h="20"
								w="20"
								bg="blue.500"
								borderRadius={"10px"}
							/>
							<GridItem
								h="20"
								w="20"
								bg="blue.500"
								borderRadius={"10px"}
							/>
						</Grid>
						<Flex>
							<Switch
								colorScheme="green"
								mr="2"
								isChecked={data?.reward_item_status}
								onChange={() => {
									handleRewardStatus(data);
								}}
							/>
							<AiFillEdit
								fontSize={"20"}
								my="2"
								color={syThemeLabelColor}
								onClick={() => {
									setSelectedReward(data);
									setIsEditClicked(prev => !prev);
								}}
							/>

							<UploadRewardStoreImage />
						</Flex>
					</Flex>
				</Flex>
			));
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text
						mb="5px"
						fontSize={"16px"}
						fontWeight={"bold"}
						color={syThemeBgYellowColor}
					>
						{"No Class List Found!!"}
					</Text>
				</Box>
			);
		}
	}

	return (
		<Box p="15px" overflow={"hidden"}>
			<Flex alignItems={"center"} borderBottom={"1px"} mb="4" pb="4">
				<Box
					as="span"
					flex="1"
					textAlign="left"
					color={syWhite}
					fontWeight={"600"}
					fontSize="20px"
				>
					Reward Store
				</Box>
				<CreateRewardStore
					classDetails={classList}
					groupDetails={groupList}
					editData={selectedReward}
					isEditClicked={isEditClicked}
					triggerCall={renderRewardList}
				/>
			</Flex>

			<Box borderBottom={"1px"} pb="2" mb="2">
				<Flex mb="3">
					<Text
						w="100px"
						fontWeight={"600"}
						fontSize={"16px"}
						color={syWhite}
					>
						Group
					</Text>
					{groupLoader ? (
						<Flex alignItems="center" justifyContent="center">
							<Spinner size="sm" color={syWhite} />
						</Flex>
					) : (
						renderGroupList()
					)}
				</Flex>

				<Flex>
					<Text
						w="100px"
						fontWeight={"600"}
						fontSize={"16px"}
						color={syWhite}
					>
						Classes
					</Text>
					{classLoader ? (
						<Flex alignItems="center" justifyContent="center">
							<Spinner size="sm" color={syWhite} />
						</Flex>
					) : (
						renderClassList()
					)}
				</Flex>
			</Box>
			<Box>
				<Text color={syWhite} fontWeight={"600"} fontSize="16px">
					Reward List
				</Text>
				{loading ? (
					<Flex alignItems="center" justifyContent="center" h="50vh">
						<Spinner size="xl" color={syWhite} />
					</Flex>
				) : (
					<Box
						mt="1rem"
						h="calc(100vh - 250px)"
						overflow="auto"
						css={{
							"&::-webkit-scrollbar": {
								width: "4px",
							},
							"&::-webkit-scrollbar-track": {
								width: "6px",
							},
							"&::-webkit-scrollbar-thumb": {
								background: "#fff",
								borderRadius: "24px",
							},
						}}
					>
						{renderStoreRewardList()}
					</Box>
				)}
			</Box>
		</Box>
	);
}

export default RewardStore;
